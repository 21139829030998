// TODO: find why this error comes up even when it's disabled in .eslintrc
/* eslint-disable space-before-function-paren */
import './src/tailwind.css';
import { defineCustomElements } from '@debtcollective/dc-header-component/loader';

export const onInitialClientRender = () => {
  // Avoid to add the script tag for addthis.com service is variable is not set
  if (!process.env.GATSBY_ADDTHIS_PUB_ID) {
    return;
  }

  const addThisScript = document.createElement('script');
  addThisScript.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${process.env.GATSBY_ADDTHIS_PUB_ID}`;
  document.body.appendChild(addThisScript);
};

export const onClientEntry = async () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
};

defineCustomElements();
